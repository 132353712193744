.ql-editor.ql-blank,
.ql-editor {
  min-height: 400px;
  background-color: rgba(245, 245, 245, 1);
}

.ql-snow .ql-picker .ql-picker-label::before,
.ql-snow .ql-picker .ql-picker-item::before {
  margin-right: 15px;
}

.email-input {
  width: 100%;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
  background-color: rgba(245, 245, 245, 1);
  padding: 10px;
  box-sizing: border-box;
}

.ql-toolbar.ql-snow {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.ql-container.ql-snow {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
