.intro-main {
  background-color: whitesmoke;
  width: 100%;
  padding: 15px;
}
.intro-add-intro-btn {
  border: none;
  outline: none;
  font-size: 20px;
  color: white;
  width: 190px;
  padding: 10px;
  max-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: rgba(69, 120, 175, 1);
  border-radius: 10px;
  cursor: pointer;
}
.intro-top-txt {
  font-size: 18px;
}
.intro-cards-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.intro-card {
  /* width: 31%; */
  position: relative;
  width: 260px;

  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0px 10px 10px;
}
.intro-card img {
  width: 240px;
  height: 265px;
}
.intro-card p {
  font-size: 18px;
  font-weight: 600;
}
.intro-card-delete-span {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  background-color: white;
  font-size: 18px;
  color: rgba(188, 42, 70, 1);
  position: absolute;
  top: 20px;
  left: 10px;
}
.intro-bottom-txt {
  font-size: 17px;
  color: rgba(172, 172, 172, 1);
  font-weight: 600;
}
.intro-add-intro-modal .ant-modal-title {
  text-align: center;
  font-size: 20px;
}
.intro-add-intro-modal-body {
  margin: 40px auto 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.intro-edit-intro-modal-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}
.intro-add-modal-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}
.intro-add-modal-avatar {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  /* background-color: #f0f0f0; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.intro-add-intro-modal-okBtn,
.intro-add-intro-modal-cancelBtn {
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  width: 48%;
  padding: 5px 0px;

  border-radius: 10px;
  cursor: pointer;
}
.intro-add-modal-txt {
    
        font-size: 17px;
        font-weight: 600;
        color: rgba(172, 172, 172, 1);
        margin: 20px 0px;
      
}
.intro-add-intro-modal-okBtn {
  background-color: rgba(69, 120, 175, 1);
}
.intro-add-intro-modal-cancelBtn {
  border: 1px solid rgba(188, 42, 70, 1);

  color: rgba(188, 42, 70, 1);
  background-color: white;
}
.intro-add-intro-modal-okBtn,
.intro-add-intro-modal-okBtn:hover,
.intro-add-intro-modal-okBtn:focus,
.intro-add-intro-modal-okBtn:focus-visible,
.intro-add-intro-modal-okBtn .ant-btn:active{
  background-color: rgba(69, 120, 175, 1) !important;
  border: none !important;
  outline: none !important;
  font-size: 18px;
  color: white !important;
  width: 48%;
  padding: 5px 0px;
box-shadow: none !important;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
}
.intro-add-intro-modal-cancelBtn,
.intro-add-intro-modal-cancelBtn:hover,
.intro-add-intro-modal-cancelBtn:focus,
.intro-add-intro-modal-cancelBtn:focus-visible,
.intro-add-intro-modal-cancelBtn .ant-btn:active {
  border: 1px solid rgba(188, 42, 70, 1) !important;

  color: rgba(188, 42, 70, 1) !important;
  background-color: white !important;
 
  outline: none !important;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  width: 48%;
  padding: 5px 0px;
box-shadow: none !important;
  border-radius: 10px;
  cursor: pointer;
}

@media all and (max-width: 767px) {
  .intro-add-intro-btn {
    width: fit-content;
    padding: 10px 5px;
    font-size: 14px;
  }
  .intro-top-txt {
    font-size: 14px;
  }
  .intro-card p {
    font-size: 14px;
    font-weight: 600;
  }
  .intro-bottom-txt,.intro-add-modal-txt {
    font-size: 14px;
  }
}
