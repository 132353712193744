.banner-add-banner-btn {
  border: none;
  outline: none;
  font-size: 20px;
  color: white;
  width: 190px;
  padding: 10px;
  max-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: rgba(69, 120, 175, 1);
  border-radius: 10px;
  cursor: pointer;
}
.banner-top-txt {
  font-size: 18px;
}
.banner-add-modal-txt {
  font-size: 17px;
  font-weight: 600;
  color: rgba(172, 172, 172, 1);
  margin: 20px 0px;
}
.intro-add-intro-modal-okBtn {
  height: 44px !important;
}

.intro-add-intro-modal-okBtn:hover {
  border: 2px solid blue;
}

@media all and (max-width: 767px) {
  .banner-add-banner-btn {
    width: fit-content;
    padding: 10px 5px;
    font-size: 14px;
  }
  .banner-top-txt {
    font-size: 14px;
  }
  .banner-add-modal-txt {
    font-size: 14px;
  }
}
