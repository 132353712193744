.tick-title-div {
  display: flex;
  justify-content: space-between;
}

.tick-top-txt {
  font-size: 18px;

  margin-top: 3px;
}
.tick-txtarea-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.tick-table {
  overflow: auto;
  margin-top: 20px;
  /* background-color: white; */
  border-radius: 10px;
  padding: 0px 0px 10px;
  position: relative;
  width: 95%;
}
.tick-table .ant-col {
  word-break: break-word;
}
.tick-table-head-row {
  padding: 20px 15px;
  background-color: rgba(228, 235, 244, 1);
  border-radius: 10px;
  margin-bottom: 15px;
}
.tick-table-row {
  padding: 5px 15px;
  background-color: rgba(244, 244, 244, 1);
  margin-top: 5px;
}
.tick-table-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.tick-table-icons-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tick-answer-ticket-div {
  display: flex;
  margin: 30px 30px 30px 20px;
}
.tick-img-div {
  width: 80px;
  display: flex;
}
.tick-img {
  width: 45px;
  height: 45px;
  margin: 5px auto;
}
.tick-answer-body {
  width: calc(100% - 80px);
  padding: 15px 0px 0px;
}
.tick-table-answer,
.tick-table-delete-div,
.tick-table-delete {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  background-color: white;
}
.tick-table-delete {
  color: rgba(188, 42, 70, 1);
  font-size: 18px;
}
.tick-table-answer {
  border: 1px solid rgba(69, 120, 175, 1);
  color: rgba(69, 120, 175, 1);
}
.tick-table-delete-div {
  color: rgba(188, 42, 70, 1);
  position: absolute;
  top: 10px;
  left: 10px;
}

.tick-send-btn {
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  width: 200px;
  padding: 5px 0px;
  background-color: rgba(69, 120, 175, 1);
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
}

@media all and (min-width: 576px) and (max-width: 767px) {
  .tick-top-txt {
    font-size: 16px;
  }
}
@media all and (max-width: 575.99px) {
  .tick-top-txt {
    font-size: 16px;
  }
}
