.login-wrapper {
  min-width: 100%;
  min-height: 100vh;
  /* background-image: url("../../../public/Images/loginBG.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}
.login-logo {
  width: 230px;
  height: 205px;
  margin-top: 30px;
}
.login-form {
  width: 700px;
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}
.login-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 20px 10px 50px;
}
.login-inputs {
  margin-top: 15px;
  border-radius: 5px;
  background-color: #f5f5f5 !important;
  border: none;
  outline: none;
  padding: 10px;
}
.login-inputs::placeholder,
.login-inputs .ant-input::placeholder {
  color: rgba(193, 193, 193, 1);
  font-size: 16px;
  font-weight: 600;
}
.login-password-eye-icon {
  cursor: pointer;
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 20px;
}
.login-btn,
.login-btn,
.login-btn:hover,
.login-btn:focus,
.login-btn:focus-visible,
.login-btn .ant-btn:active {
  border: none;
  outline: none;
  height: 40px;
  width: 100%;
  background-color: rgba(53, 113, 177, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  box-shadow: none !important;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

@media all and (min-width: 577px) and (max-width: 767px) {
  .login-form {
    width: 80%;
  }
  .login-form form {
    width: 90%;
  }
  .login-logo {
    width: 160px;
    height: 130px;
  }
}

@media all and (max-width: 576.999px) {
  .login-form {
    width: 80%;
  }
  .login-form form {
    width: 90%;
  }
  .login-logo {
    width: 160px;
    height: 130px;
  }
}
