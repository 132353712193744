*{
  margin: 0px;
  padding: 0px;
  direction: rtl;
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
 
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
p {
  word-break: break-word;
}
/* Remove arrows from input type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*** styles for scroll bars **/
:root {
  --code-color: darkred;
  --code-bg-color: #aaaaaa;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}

pre {
  color: var(--code-color);
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  background-color: var(--code-bg-color);
}

.code-block {
  max-height: 100px;
  overflow: auto;
  padding: 8px 7px 5px 15px;
  margin: 0px 0px 0px 0px;
  border-radius: 7px;
}
::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

  /* * {
      scrollbar-width:thin;
      scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  } */

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
      width: 15px;
      height: 15px;
      scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  }

  *::-webkit-scrollbar-track {
      background: var(--scroll-bar-bg-color);
  }

  *::-webkit-scrollbar-thumb {
      background-color: var(--scroll-bar-color);
      border-radius: 20px;
      border: 3px solid var(--scroll-bar-bg-color);
  }
