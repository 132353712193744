.sec-main {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.repo-questions-btn,
.repo-answers-btn {
  padding: 10px;
  width: 120px;
  border: none;
  outline: none;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}
.repo-questions-btn {
  color: rgba(69, 120, 175, 1);
}

.repo-answers-btn {
  margin-right: 20px;
  color: rgba(69, 120, 175, 1);
}
.active {
  background-color: rgba(69, 120, 175, 0.3);
  color: rgba(69, 120, 175, 1);
}
.repo-top-txt {
  font-size: 18px;
}

@media all and (max-width: 767px) {
  .repo-top-txt {
    font-size: 14px;
  }
  .repo-answers-btn,
  .repo-questions-btn {
    width: fit-content;
    padding: 10px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .repo-answers-btn {
    margin-right: 10px;
  }
}
