@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@layer utilities {
  .trns {
    @apply transition-all duration-300 ease-in-out;
  }

  .absolute__center {
    @apply absolute translate-x-1/2 right-1/2 bottom-1/2 translate-y-1/2;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .flex-col-center {
    @apply flex flex-col justify-center items-center;
  }
}
