.features-main {
  background-color: whitesmoke;
  width: 100%;
  padding: 15px;
}
.features-top-txt {
  font-size: 18px;
}
.features-add-features-btn {
  border: none;
  outline: none;
  font-size: 20px;
  color: white;
  width: 190px;
  padding: 10px;
  max-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: rgba(69, 120, 175, 1);
  border-radius: 10px;
  cursor: pointer;
}
.features-content-div {
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 25px;
}
.features-icons-div {
  display: flex;
  justify-content: end;
  align-items: center;
}
.features-edit,
.features-delete {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  background-color: white;
}
.features-edit {
  color: rgba(249, 166, 54, 1);
}
.features-delete {
  color: rgba(188, 42, 70, 1);
}
.features-txt-div-container {
  margin-top: 25px;
  max-height: 400px;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.features-txt-div {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
}
.features-add-features-modal-body {
  margin: 20px auto 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features-edit-features-modal-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}
.features-add-features-modal-body .quill {
  width: 100%;
}
.features-add-features-modal-body .quill .ql-toolbar.ql-snow {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 10px;
  border: none;
}
.features-add-features-modal-body .ql-container.ql-snow {
  background-color: rgba(244, 244, 244, 1);
  border: none;
  border-radius: 10px;
  min-height: 150px;
  max-height: 250px;
  overflow-y: auto;
}
.features-add-features-modal-body .ql-snow .ql-picker.ql-size {
  width: unset;
}
.features-edit-features-modal-okBtn,
.features-edit-features-modal-okBtn:hover,
.features-edit-features-modal-okBtn:focus,
.features-edit-features-modal-okBtn:focus-visible,
.features-edit-features-modal-okBtn .ant-btn:active,
.features-edit-features-modal-cancelBtn,
.features-edit-features-modal-cancelBtn:hover,
.features-edit-features-modal-cancelBtn:focus,
.features-edit-features-modal-cancelBtn:focus-visible,
.features-edit-features-modal-cancelBtn .ant-btn:active {
  border: none !important;
  outline: none !important;
  font-size: 18px;

  width: 48%;
  padding: 5px 0px;
  box-shadow: none !important;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.features-edit-features-modal-okBtn,
.features-edit-features-modal-okBtn:hover,
.features-edit-features-modal-okBtn:focus,
.features-edit-features-modal-okBtn:focus-visible,
.features-edit-features-modal-okBtn .ant-btn:active {
  border: none !important;
  background-color: rgba(69, 120, 175, 1) !important;
  color: white !important;
}
.features-edit-features-modal-cancelBtn,
.features-edit-features-modal-cancelBtn:hover,
.features-edit-features-modal-cancelBtn:focus,
.features-edit-features-modal-cancelBtn:focus-visible,
.features-edit-features-modal-cancelBtn .ant-btn:active {
  border: 1px solid rgba(188, 42, 70, 1) !important;

  color: rgba(188, 42, 70, 1) !important;
  background-color: white !important;
}
.features-add-features-modal-body .ql-editor,
.features-add-features-modal-body .ql-blank {
  min-height: 150px !important;
}
@media all and (max-width: 767px) {
  .features-top-txt {
    font-size: 16px;
  }
  .features-add-features-btn {
    width: fit-content;
    padding: 10px 5px;
    font-size: 14px;
  }
  .features-txt-div {
    font-size: 16px;
  }
}
