.main_sidebar, .main_sidebar .ant-layout-sider-children, .main_sidebar .ant-menu {
    background-color: rgba(255, 255, 255, 1) !important;
    
}
.ant-layout .ant-layout-sider {
    position: sticky !important;
    top: 0 !important;
    /* bottom: 0 !important; */
    height: 100vh;
    overflow-y:  auto !important;
   
}
.ant-layout-sider,:where(.css-dev-only-do-not-override-1ae8k9u).ant-layout .ant-layout-sider,:where(.css-dev-only-do-not-override-1ae8k9u).ant-layout .ant-layout-sider-has-trigger {
    width: 30% ;
    min-width: 354px ;
    flex: 0 0 354px ;
}
.ant-layout-sider-children .logo {
    display: flex;
    justify-content: center ;
}
.logo .sidebar-logo {
    margin-top: 10px;
}
.sidebar-menus-div {
    margin-top: 10px;
    max-height: calc(100% - 120px);
    overflow-y: auto;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.main_sidebar .ant-menu, .main_sidebar .ant-menu-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 30px; */
}
:where(.css-1ae8k9u).ant-menu-dark .ant-menu-item:not(.ant-menu-item-disabled):focus-visible{
    outline: none !important;
    box-shadow: none !important;
}
.sidebar_item {
    color:rgba(151, 151, 151, 1) !important;
    font-size: 18px;
    font-weight: 600;
}
.main_sidebar :where(.css-dev-only-do-not-override-1ae8k9u).ant-menu-dark .ant-menu-item-selected, .main_sidebar .ant-menu-item:focus, :where(.css-1ae8k9u).ant-menu-dark .ant-menu-item-selected, .ant-menu-item:focus-visible, .ant-menu-item-selected, .sidebar_item:focus{
    color: rgba(69, 120, 175, 1) !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;

    outline: none !important;
    /* border-right: 3px solid; */
}
.sidebar_item {
    display: flex;
    justify-content: center;
    width: 90% !important;
}

.sidebar_item svg {
    font-size: 18px !important;
}
.sidebar_item .ant-menu-title-content {
    max-width: 150px !important;
    width: unset !important;
}
.ant-layout-sider-trigger {
    color: rgba(255, 255, 255, 1) !important;
    background-color: rgba(255, 255, 255, 1) !important;
}


@media all and (max-width: 767px) {
    .logo .sidebar-logo {
        margin-top: 50px;
        object-fit: contain;
        object-position: center;
    }
    
}

@media all and (max-width: 899px) {
    .sidebar_item {
        
        font-size: 14px;
        
    }
}
@media all and (min-width: 900px) and (max-width: 1045px) {
    .sidebar_item {
        font-size: 16px;
    }
}
