.sec-add-sec-btn {
  border: none;
  outline: none;
  font-size: 20px;
  color: white;
  width: 210px;
  max-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: rgba(69, 120, 175, 1);
  border-radius: 10px;
  cursor: pointer;
}

.sec-top-txt {
  font-size: 18px;
}
.sec-table {
  overflow: auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 0px 0px 10px;
}
.sec-table .ant-col {
  word-break: break-word;
}
.sec-table-head-row {
  padding: 20px 15px;
  background-color: rgba(228, 235, 244, 1);
  border-radius: 10px;
  margin-bottom: 15px;
}
.sec-table-row {
  padding: 5px 15px;
  background-color: rgba(244, 244, 244, 1);
  margin-top: 5px;
}
.sec-table-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.sec-table-edit,
.sec-table-delete {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  cursor: pointer;
  background-color: white;
}
.sec-table-edit {
  color: rgba(249, 166, 54, 1);
}
.sec-table-delete {
  color: rgba(188, 42, 70, 1);
}
.sec-add-section-modal .ant-modal-title {
  text-align: center;
  font-size: 20px;
}
.sec-add-sec-modal-body {
  margin: 40px auto 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sec-add-sec-modal-input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: rgba(245, 245, 245, 1);
  padding: 10px;
  box-sizing: border-box;
}
/* .sec-add-sec-modal-btn,
.sec-add-sec-modal-btn:hover,
.sec-add-sec-modal-btn:focus,
.sec-add-sec-modal-btn:focus-visible,
.sec-add-sec-modal-btn .ant-btn:active {
  border: none !important;
  outline: none !important;
  font-size: 20px;
  color: white !important;
  
  
  max-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  background-color: rgba(69, 120, 175, 1) !important;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: none !important;
  
} */

.sec-add-sec-modal-btn,
.sec-add-sec-modal-btn:hover,
.sec-add-sec-modal-btn:focus,
.sec-add-sec-modal-btn:focus-visible,
.sec-add-sec-modal-btn .ant-btn:active {
  border: none !important;
  outline: none;
  font-size: 18px;
  color: white !important;
  width: 100%;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  background-color: rgba(69, 120, 175, 1) !important;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
  box-shadow: none !important;
}
.sec-edit-sec-modal-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}

.sec-edit-sec-modal-okBtn,
.sec-edit-sec-modal-cancelBtn {
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  width: 48%;
  padding: 5px 0px;

  border-radius: 10px;
  cursor: pointer;
}
.sec-edit-sec-modal-okBtn {
  border: none;
  background-color: rgba(69, 120, 175, 1);
  color: white;
}
.sec-edit-sec-modal-cancelBtn {
  border: 1px solid rgba(188, 42, 70, 1);

  color: rgba(188, 42, 70, 1);
  background-color: white;
}

@media all and (min-width: 576px) and (max-width: 767px) {
  .sec-add-sec-btn {
    font-size: 18px;

    width: 150px;
    padding: 10px;
  }
  .sec-top-txt {
    font-size: 16px;
  }
}
@media all and (max-width: 575.99px) {
  .sec-add-sec-btn {
    font-size: 16px;

    width: 130px;
    padding: 5px;
  }
  .sec-top-txt {
    font-size: 16px;
  }
}
