.tick-table .ant-table-content thead , .tick-table .ant-table-content thead tr {
    background-color: rgba(228, 235, 244, 1) !important;
    border-radius: 10px !important;
    border-bottom: 8px solid white !important;
  
    
}
.tick-table .ant-table-thead > tr > th:first-child {
   
   border-bottom-right-radius: 10px !important;
  }
  
  /* Style the last cell in the table header */
 .tick-table .ant-table-thead > tr > th:last-child {
    /* Add your styles here */
    border-bottom-left-radius: 10px !important;
    
  }
.tick-table .ant-table-tbody, .tick-table :where(.css-dev-only-do-not-override-1kuana8).ant-table-wrapper table {
    border-collapse: collapse !important;
}
.tick-table .ant-table-content th, .tick-table .ant-table-content :where(.css-dev-only-do-not-override-1kuana8).ant-table-wrapper .ant-table-thead >tr>th {
    background-color: transparent !important;
}
.tick-table .ant-table-content tbody tr {
    border-bottom: 4px solid white !important;
    background-color: rgba(244, 244, 244, 1) !important;
    border-radius: 10px !important;
    
   
}

  