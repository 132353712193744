.search-div {
    width: 55%;
  }
  .search_block {
      background-color: rgba(255, 255, 255, 1);
      /* width: 55%; */
      /* margin-top: 60px; */
      min-height: 45px;
      color: #131c3b;
      font-size: 14px;
      height: 1.6em;
      padding: 0px 10px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }
  
    
    .searchRow .searchIconCol {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
    }
    .searchRow,
    .searchRow .searchInputCol {
      height: 100%;
      padding: 0.15em;
    }
    .search_input {
      background-color: 
      rgba(255, 255, 255, 1);
    }
    .searchRow .searchCol {
      display: flex;
      justify-content: center;
      align-items: end;
      /* height: 100%; */
    }
    .searchRow .searchInputCol .search_input {
      height: 100%;
      width: 100%;
      padding: 5px;
      border: none;
      outline: none;
      margin-left: 10px;
      font-size: 17px;
    }
    .searchRow .searchInputCol .search_input::placeholder {
      opacity: 0.5;
    }
    
    .search_icon {
      /* color: #a0acac; */
      font-size: 18px;
      font-weight: bold;
      opacity: 0.2;
    }
    
    @media only screen and (max-width: 768px) {
      .search_block {
        font-size: 16px;
        /* width: 50%; */
      }
    
      .search_icon {
        font-size: 15px;
      }
    }
    @media all and (max-width: 600px) {
      .searchRow .searchInputCol .search_input {
        font-size: 12px;
      }
    }
    