.inv-title-div {
  display: flex;
  justify-content: space-between;
}

.inv-table {
  overflow: auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 0px 0px 10px;
}
.inv-table .ant-col {
  word-break: break-word;
}
.inv-table-head-row {
  padding: 20px 15px;
  background-color: rgba(228, 235, 244, 1);
  border-radius: 10px;
  margin-bottom: 15px;
}
.inv-table-head-col {
  font-size: 20px;
  font-weight: 600;
}
.inv-table-row {
  padding: 5px 15px;
  background-color: rgba(244, 244, 244, 1);
  margin-top: 5px;
}
.inv-table-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.inv-table-delete-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inv-table-delete {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  background-color: white;
}

.inv-table-delete {
  color: rgba(188, 42, 70, 1);
}
