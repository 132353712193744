.main-main {
  
    background-color: whitesmoke;
    width: 100%;
    padding: 15px;
  
}
.main-control-txt {
  color: rgba(151, 151, 151, 1); font-size: 18px;
}
.main-tables {
  display: flex;
  justify-content: space-between;
}
.main-employees {
  max-height: 600px;
   overflow: auto;
}
.main-employees-header-div {
  
    position: sticky;
    top: 0;
    z-index: 8;
    background-color: rgba(252, 252, 252, 1);
    min-width: 300px;
  
}
.main-employees-header-txt {
  
    font-size: 16px;
    padding: 15px;

    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  
}
.reportUnClicked{
  
    padding: 10px 15px;
    border: none;
    outline: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 5px;
  
}
.reportClicked{

    padding: 10px 15px;
    border: none;
    outline:none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    color: rgba(69, 120, 175, 1);
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 5px;
  
}
.main-top-cards-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  padding: 15px;
  margin-top: 20px;
}
.main-top-cards {
  border: 1px dotted rgba(218, 218, 218, 1);
  border-radius: 10px;
  padding: 15px;
  width: 32%;
  min-width: 250px;
  margin: 10px 2px;
  box-sizing: border-box;
}
.main-top-cards .main-top-cards-txt {
  color: rgba(125, 125, 125, 1);
  font-size: 16px;
  font-weight: 500;
}
.main-top-cards .main-top-cards-num {
  font-size: 25px;
  font-weight: 700;
}
@media all and (max-width: 1454px) {
  .main-top-cards {
    min-width: 24%;
  }
}
@media all and (max-width: 1000px) {
  .main-top-cards {
    min-width: 48%;
  }
}
/* @media all and (max-width: 767px) {
    .main-top-cards{
      min-width: 250px;
    }
  } */
@media all and (max-width: 576px) {
  .main-top-cards {
    min-width: 100%;
  }
}
.main-employees {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 14px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  margin-top: 15px;
}
.main-employees .ant-col {
  color: rgba(81, 81, 81, 1);
  font-size: 15px;
  font-weight: 600;
  word-break: break-word;
  /* border-bottom: 1px solid black; */
}
.main-employees-body .main-employees-body-row {
  border-bottom: 1px solid rgb(240, 240, 240);
}
.main-employees-body .main-employees-body-row:last-child {
  border-bottom: none;
}
.main-admin-employees {
  width: 49%;
}
.main-crud-div-wrapper {
  display: flex;
  flex-direction: column;
  width: 49%;
}
.main-crud-div {
  /* margin-top: 20px; */
  background-color: rgba(255, 255, 255, 1);
  border-radius: 14px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);

  width: 100%;
}
.main-tabs {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0px 10px;
  color: rgba(151, 151, 151, 1);
  padding: 5px 0px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.main-tabs:active,
.main-tabs:focus {
  color: rgba(255, 97, 29, 1);
  border-bottom: 2px solid rgba(255, 97, 29, 1);
}
.main-tabs-body {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.main-add-user {
  margin-top: 15px;
  border-radius: 5px;
  background-color: rgba(247, 247, 247, 1);
  border: none;
  outline: none;
  padding: 10px;
}
.main-tabs-body
  :where(.css-dev-only-do-not-override-1ae8k9u).ant-input-outlined:hover,
.main-tabs-body
  :where(.css-dev-only-do-not-override-1ae8k9u).ant-input-outlined:focus {
  background-color: rgba(247, 247, 247, 1) !important;
  box-shadow: none !important;
  border: none !important;
}
.main-tabs-body input::placeholder {
  color: rgba(151, 151, 151, 1) !important;
}
.main-add-user .ant-select-selector,
.main-add-user .ant-select-selector:focus,
.ant-select-focused .ant-select-selector,
.main-add-user
  :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.main-add-user
  :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none !important;
  /* border-color: transparent !important; */
  box-shadow: none !important;
  background-color: transparent !important;
  outline: none !important;
}

.main-tabs-body .ant-select {
  padding: 22px 0px;
  color: black;
}
.main-tabs-body .ant-select-selection-placeholder {
  color: rgba(151, 151, 151, 1) !important;
  opacity: 1 !important;
}

.main-add-user-btns {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.main-add-user-btn,
.main-cancel-add-user-btn {
  min-width: 150px;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-add-user-btn,
.main-add-user-btn:hover,
.main-add-user-btn:focus,
.main-add-user-btn:focus-visible,
.main-add-user-btn .ant-btn:active {
  color: rgba(255, 97, 29, 1) !important;
  background-color: rgba(255, 241, 235, 1) !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.main-cancel-add-user-btn,
.main-cancel-add-user-btn:hover,
.main-cancel-add-user-btn:focus,
.main-cancel-add-user-btn:focus-visible {
  border: 1px solid rgba(151, 151, 151, 1) !important;
  color: rgba(151, 151, 151, 1) !important;
  box-shadow: none !important;
  outline: none !important;
}
.ant-btn:focus {
  outline: none !important;
}
.ant-btn:focus,
.ant-btn:hover {
  box-shadow: none !important;
  /* Add any other styles as needed */
}
.main-edit-img-btn {
  background-color: transparent;
  min-width: 80px;
  padding: 3px 10px;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  border: 1px solid rgba(151, 151, 151, 1);
  color: rgba(151, 151, 151, 1);
  outline: none;
}

.main-logout-btn-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0px;
}
.main-logout-btn-div .main-logout-btn {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.main-edit-pass-inputs {
  margin-top: 15px;
  border-radius: 5px;
  background-color: rgba(247, 247, 247, 1) !important;
  border: none;
  outline: none;
  padding: 10px;
}

@media all and (max-width: 576.9px) {
  .main-tables {
    display: flex;
    flex-direction: column;
  }
  .main-admin-employees {
    width: 100%;
  }
  .main-crud-div-wrapper {
    width: 100%;
    margin-top: 30px;
  }
  .main-crud-div {
    width: 100%;
    /* margin-top: 30px; */
  }
  .main-tabs,
  .main-employees .ant-col {
    font-size: 14px;
  }
  .main-employees {
    margin-top: 30px;
  }
  .main-add-user-btn,
  .main-cancel-add-user-btn {
    width: unset;
    min-width: 80px;
    padding: 10px;
  }
  .main-edit-img-btn {
    font-size: 12px;
  }
}
@media all and (min-width: 577px) and (max-width: 767px) {
  .main-tables {
    display: flex;
    flex-direction: column;
  }
  .main-admin-employees {
    width: 80%;
  }
  .main-crud-div-wrapper {
    width: 80%;
    margin-top: 30px;
  }
  .main-crud-div {
    width: 100%;
    /* margin-top: 30px; */
  }
  .main-tabs,
  .main-employees .ant-col {
    font-size: 14px;
  }
  .main-add-user-btn,
  .main-cancel-add-user-btn {
    width: unset;
    min-width: 80px;
    padding: 10px;
  }
}
@media all and (min-width: 767px) and (max-width: 950px) {
  .main-tables {
    display: flex;
    flex-direction: column;
  }
  .main-admin-employees {
    width: 80%;
  }
  .main-crud-div-wrapper {
    width: 80%;
    margin-top: 30px;
  }
  .main-crud-div {
    width: 100%;
    /* margin-top: 30px; */
  }
}

/*******packages*******/

.main-packages-wrapper {
  margin-top: 40px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  min-width: 300px;
  padding: 15px;
}
.main-package-card {
  width: 200px;

  border-radius: 14px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);

  align-items: center;
  margin: 10px 10px;
}
.main-card-titles {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.main-card-address {
  margin-top: 10px;
  font-size: 14px;
  width: 90%;
  word-wrap: break-word;
  color: rgba(151, 151, 151, 1);
  text-align: center;
}
.main-card-time {
  margin-top: 10px;
  font-size: 16px;
  width: 90%;
  word-wrap: break-word;
  font-weight: 600;
  text-align: center;
}

@media all and (min-width: 600px) and (max-width: 1045px) {
  .main-packages-wrapper {
    width: 490px;
  }
}

@media all and (max-width: 599px) {
  .main-packages-wrapper {
    width: fit-content;
  }
}
