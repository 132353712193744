.header-main {
    z-index: 10;
  height: 120px;
  background-color: white;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
.header-main input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
.header-imgs-div {
  width: 40%;
  display: flex;
  margin: 60px 50px 15px 0px;
justify-content: center;
  align-items: center;
  /* padding: 0px 50px 0px 30px; */
  box-sizing: border-box;
}
.header-imgs-div .notifi-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-imgs {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 0 2px gray;
    line-height: 45px;
    margin:0px 15px;
}

.header-span-imgs {
    display:inline-block;
    width:2px;
    height:50px;
    background-color:rgba(235, 235, 235, 1);
}

@media all and (min-width: 577px) and (max-width: 767px) {
    .header-imgs-div{
        width: auto;
        margin: 60px 10px 15px 0px;
    }
    .header-imgs{

    }
}
@media all and (max-width: 576.99px) {
    .header-imgs-div{
        width: auto;
        margin: 60px 10px 15px 0px;
        font-size: 12px;
    }
    .header-imgs{
        width: 45px;
        height: 45px;
        margin: 0px 10px;
    }
    .header-span-imgs {
        
        height:30px;
        
    }
    
}
