.settings {
  padding: 16px 16px 100px 16px;
  width: 100%;
}
.settings .container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.sett-tables-div{
  display: flex;
  justify-content: space-between;
}
.sett-tables-div .edit-info {
  width: 48%;
}
.sett-tables-div .tick-table {
  /* width: 48%; */
  margin: 20px 20px;
}
.sett-emp-table .ant-table-content thead th {
  padding: 10px;
}
.sett-emp-table .ant-table-content thead th:last-child {
  text-align: end !important;
}
.sett-add-emp-btn {
  width: 100%;
  max-width: 130px;
  padding: 10px;
  border-radius: 10px;
  min-height: 50px;
  border: none;
  outline: none;
  background-color: white;
  color: rgba(69, 120, 175, 1);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.sett-add-emp-modal {
  top: 10px !important;
}
.sett-add-emp-modal .ant-modal-body {
  max-height: 450px !important;
  overflow-y: auto;
}
.sec-add-section-modal .react-tel-input {
  display: flex !important;
  direction: ltr !important;
  margin-top: 15px;
  min-height: 45px !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.sec-add-section-modal .react-tel-input .form-control {
  min-height: 45px !important;
  direction: ltr !important;
  padding: 10px !important;
  text-align: right !important;

}

.react-tel-input .flag-dropdown{
  position: unset !important;
  border: none !important;
  padding: 5px !important;
  background-color: rgba(245, 245, 245, 1) !important;
  border-left: 1px solid white;
}

.react-tel-input .flag-dropdown .arrow{
  right:  20px !important;
}
/* .sec-add-section-modal .react-tel-input .flag{
  width: 30px;
  height: 20px;
} */
.sett-select-menu, .sec-add-section-modal .ant-select {
  width: 100% !important;
  background-color: rgba(245, 245, 245, 1);
  padding: 10px;
  border-radius: 10px;
  min-height: 45px;
}
.sec-add-section-modal .ant-picker {
  width: 100%;
  margin-top: 15px;
  background-color: rgba(245, 245, 245, 1);
  padding: 10px;
  min-height: 45px;
}
:where(.css-dev-only-do-not-override-1kuana8).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}

.settings .container .header {
  font-size: 18px;
}
.settings .container .header span:first-child {
  color: rgba(151, 151, 151, 1);
}

.settings .container .body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.settings .container .body .info {
  width: 704px;
  height: 169px;
  padding: 18px;
  border-radius: 14px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 16px;
}

.settings .container .body .info img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.settings .container .body .info .text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 17px;
  font-weight: 600;
}

.settings .container .body .info .text-container span:last-child {
  color: #acacac;
}

.settings .container .body .edit-info {
  width: 48%;
  min-height: 169px;
  border-radius: 14px;
  background-color: white;
}

.settings .wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: white;
  border-radius: 14px;
}

.wrapper .header {
  background-color: #e4ebf4;
  border-radius: 14px 14px 0px 0px;
}

.header .list {
  list-style: none;
  display: flex;
  align-items: center;
}

.header .list li {
  padding: 10px 16px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.settings .active {
  border-bottom: 3px solid #4578af;
  color: #4578af;
}

.form-container {
  width: 100%;
  padding: 24px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-control {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.actions p {
  font-size: 17px;
  font-weight: 600;
  color: #acacac;
}

.form-control img {
  width: 95px;
  height: 95px;
  object-fit: cover;
  border-radius: 50%;
}

.form-control input[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0px;
}

.form-control label {
  width: 87px;
  border-radius: 10px;
  border: 1px solid #979797;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: #acacac;
  cursor: pointer;
}

.form-control input {
  width: 100%;
  border-radius: 5px;
  background-color: #f7f7f7;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
}

.form-actions {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.form-actions button {
  width: 208px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: #4578af;
  cursor: pointer;
}

.form-actions button:last-child {
  border: 2px solid #bc2a46;
  color: #bc2a46;
  background-color: white;
}

.second-icon {
  position: absolute;
  left: 10px;
  top: 9px;
  color: #8b8787;
  padding: 5px;
  font-size: 28px;
  border-radius: 50%;
  cursor: pointer;
}

.error-message {
  color: red;
}
@media all and (max-width: 1100px) {
  .sett-tables-div{
    display: flex;
    flex-direction: column;
  }
  .sett-tables-div .edit-info {
    width: 90% !important;
  }
  .sett-tables-div .tick-table {
    width: 90%;
  }
  
}

@media all and (max-width: 768px) {
  .settings .info {
    width: 100% !important;
    height: auto !important;
  }
  .info img {
    width: 85px !important;
    height: 85px !important;
  }
  .info span {
    font-size: 12px;
  }

  .edit-info {
    width: 100% !important;
  }
  .edit-info img {
    width: 85px !important;
    height: 85px !important;
  }
  .form-container {
    padding: 16px 10px;
  }
  .edit-info .image-name {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .form-actions button {
    width: 148px;
    padding: 5px 8px;
    font-size: 14px;
    height: 40px;
  }
  .form-control {
    flex-direction: column;
    align-items: center;
  }
  .actions {
    flex-direction: column;
    align-items: center;
    width: 100% !important;
  }

  .header .list li {
    font-size: 16px;
  }
}
