.rules-main {
    background-color: whitesmoke;
    width: 100%;
    padding: 15px;
  }
  .rules-top-txt {
    font-size: 18px;
  }
  .rules-add-rules-btn {
    border: none;
    outline: none;
    font-size: 20px;
    color: white;
    width: 190px;
    padding: 10px;
    max-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    background-color: rgba(69, 120, 175, 1);
    border-radius: 10px;
    cursor: pointer;
  }
  .rules-content-div {
    background-color: white;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 25px;
  }
  .rules-icons-div {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .rules-edit,
  .rules-delete {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    cursor: pointer;
    background-color: white;
  }
  .rules-edit {
    color: rgba(249, 166, 54, 1);
  }
  .rules-delete {
    color: rgba(188, 42, 70, 1);
  }
  .rules-txt-div-container {
    margin-top: 25px;
    max-height: 400px;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  .rules-txt-div {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  .rules-add-rules-modal-body {
      margin: 20px auto 10px;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .rules-edit-rules-modal-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      width: 100%;
    }
    .rules-add-rules-modal-body .quill {
      width: 100%;
    }
    .rules-add-rules-modal-body .quill .ql-toolbar.ql-snow {
      display: flex;
      justify-content: end;
      margin-bottom: 15px;
      background-color: rgba(244, 244, 244, 1);
      border-radius: 10px;
      border: none;
    }
    .rules-add-rules-modal-body .ql-container.ql-snow{
      background-color: rgba(244, 244, 244, 1);
      border: none;
      border-radius: 10px;
      min-height: 150px;
      max-height: 250px;
      overflow-y: auto;
    }
    .rules-add-rules-modal-body .ql-snow .ql-picker.ql-size {
      width:unset;
    }
    .rules-edit-rules-modal-okBtn,
  .rules-edit-rules-modal-okBtn:hover,
  .rules-edit-rules-modal-okBtn:focus,
  .rules-edit-rules-modal-okBtn:focus-visible,
  .rules-edit-rules-modal-okBtn .ant-btn:active,
  .rules-edit-rules-modal-cancelBtn,
  .rules-edit-rules-modal-cancelBtn:hover,
  .rules-edit-rules-modal-cancelBtn:focus,
  .rules-edit-rules-modal-cancelBtn:focus-visible,
  .rules-edit-rules-modal-cancelBtn .ant-btn:active {
    border: none !important;
    outline: none !important;
    font-size: 18px;
    
    width: 48%;
    padding: 5px 0px;
  box-shadow: none !important;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
    border-radius: 10px;
    cursor: pointer;
  }
  .rules-edit-rules-modal-okBtn,.rules-edit-rules-modal-okBtn:hover,
  .rules-edit-rules-modal-okBtn:focus,
  .rules-edit-rules-modal-okBtn:focus-visible,
  .rules-edit-rules-modal-okBtn .ant-btn:active {
    border: none !important;
    background-color: rgba(69, 120, 175, 1) !important;
    color: white !important;
  }
  .rules-edit-rules-modal-cancelBtn,.rules-edit-rules-modal-cancelBtn:hover,
  .rules-edit-rules-modal-cancelBtn:focus,
  .rules-edit-rules-modal-cancelBtn:focus-visible,
  .rules-edit-rules-modal-cancelBtn .ant-btn:active  {
    border: 1px solid rgba(188, 42, 70, 1) !important;
  
    color: rgba(188, 42, 70, 1) !important;
    background-color: white !important;
  }
  
  @media all and (max-width: 767px) {
    .rules-top-txt, .rules-txt-div {
      font-size: 16px;
    }
    .rules-add-rules-btn {
      width: fit-content;
      padding: 10px 5px;
      font-size: 14px;
    }
  }
  .ql-editor {
    direction: rtl;
    text-align: right;
  }