.pack-add-pack-btn {
  border: none;
  outline: none;
  font-size: 20px;
  color: white;
  width: 210px;
  max-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: rgba(69, 120, 175, 1);
  border-radius: 10px;
  cursor: pointer;
}
.pack-top-txt {
  font-size: 18px;
}
.pack-packages-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.pack-packages-card {
  width: 31%;
  min-width: 270px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0px 10px 10px;
}
.pack-icons-div {
  display: flex;
  justify-content: end;
  align-items: center;
}
.pack-table-edit,
.pack-table-delete {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  background-color: white;
}
.pack-table-edit {
  color: rgba(249, 166, 54, 1);
}
.pack-table-delete {
  color: rgba(188, 42, 70, 1);
}
.pack-wallet-icon-span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(227, 235, 243, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pack-wallet-icon {
  font-size: 18px;
  color: rgba(69, 120, 175, 1);
}
.pack-card-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  color: rgba(69, 120, 175, 1);
}
.pack-add-section-modal .ant-modal-title {
  text-align: center;
  font-size: 20px;
}
.pack-add-pack-modal-body {
  margin: 40px auto 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pack-add-pack-modal-input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: rgba(245, 245, 245, 1);
  padding: 10px;
  box-sizing: border-box;
}
.pack-add-pack-modal-btn {
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  width: 100%;
  padding: 5px 0px;
  background-color: rgba(69, 120, 175, 1);
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
}
.pack-add-pack-modal-btn,
.pack-add-pack-modal-btn:hover,
.pack-add-pack-modal-btn:focus,
.pack-add-pack-modal-btn:focus-visible,
.pack-add-pack-modal-btn .ant-btn:active {
  border: none !important;
  outline: none;
  box-shadow: none !important;
  font-size: 18px;
  color: white !important;
  width: 100%;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  background-color: rgba(69, 120, 175, 1) !important;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
}
.pack-edit-pack-modal-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}


.pack-edit-pack-modal-okBtn,
.pack-edit-pack-modal-okBtn:hover,
.pack-edit-pack-modal-okBtn:focus,
.pack-edit-pack-modal-okBtn:focus-visible,
.pack-edit-pack-modal-okBtn .ant-btn:active,
.pack-edit-pack-modal-cancelBtn,
.pack-edit-pack-modal-cancelBtn:hover,
.pack-edit-pack-modal-cancelBtn:focus,
.pack-edit-pack-modal-cancelBtn:focus-visible,
.pack-edit-pack-modal-cancelBtn .ant-btn:active {
  border: none !important;
  outline: none !important;
  font-size: 18px;
  
  width: 48%;
  padding: 5px 0px;
box-shadow: none !important;
min-height: 45px;
display: flex;
justify-content: center;
align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.pack-edit-pack-modal-okBtn,.pack-edit-pack-modal-okBtn:hover,
.pack-edit-pack-modal-okBtn:focus,
.pack-edit-pack-modal-okBtn:focus-visible,
.pack-edit-pack-modal-okBtn .ant-btn:active {
  border: none !important;
  background-color: rgba(69, 120, 175, 1) !important;
  color: white !important;
}
.pack-edit-pack-modal-cancelBtn,.pack-edit-pack-modal-cancelBtn:hover,
.pack-edit-pack-modal-cancelBtn:focus,
.pack-edit-pack-modal-cancelBtn:focus-visible,
.pack-edit-pack-modal-cancelBtn .ant-btn:active  {
  border: 1px solid rgba(188, 42, 70, 1) !important;

  color: rgba(188, 42, 70, 1) !important;
  background-color: white !important;
}
@media all and (min-width: 576px) and (max-width: 767px) {
  .pack-add-pack-btn {
    font-size: 18px;

    width: 160px;
    padding: 10px;
  }
  .pack-top-txt {
    font-size: 16px;
  }
}
@media all and (max-width: 575.99px) {
  .pack-add-pack-btn {
    font-size: 16px;

    width: 130px;
    padding: 5px;
  }
  .pack-top-txt {
    font-size: 16px;
  }
}
